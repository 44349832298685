import React, { useEffect, useState } from 'react';

function Contact(){

    const [mailBody, setMailBody] = useState('');

    useEffect(() => {        
        document.title ="Contact"
    })

    let CreateMail = (event) => {
        event.preventDefault();
        window.open(`mailto:jdarling183@gmail.com?subject=jdarling.dev&body=${mailBody}`, '_blank')
    }

    let UpdateMail = (event) => {
        console.log(event)
        if(event.target.value.length <= 1200){
            setMailBody(event.target.value);
        }
    }

    return(
        <form onSubmit={CreateMail} style={{display: 'flex', flexDirection: 'column'}}>
            <h1>Send me an Email!</h1>
            <label htmlFor='body'>Message</label>
            <textarea id='body' rows={20} value={mailBody} onChange={UpdateMail}/>
            <code>Characters Remaining {1200 - mailBody.length}</code>
            <button style={{width: 'fit-content', borderRadius: '5px', float: 'right'}}>Send Mail</button>
            <span>You can also reach me at <span className='emailLink' onClick={() => navigator.clipboard.writeText('jdarling183@gmail.com')}>jdarling183 at gmail.com</span></span>
        </form>
    )
}

export default Contact;